.project-details .portfolio-details-slider img {
  width: 100%;
  max-height: 100vh;
  object-fit: contain;
  object-position: center;
  background-color: #000;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.project-details .swiper-button-prev,
.project-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.project-details .swiper-button-prev:after,
.project-details .swiper-button-next:after {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.project-details .swiper-button-prev:hover:after,
.project-details .swiper-button-next:hover:after {
  background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 575px) {

  .project-details .swiper-button-prev,
  .project-details .swiper-button-next {
    display: none;
  }
}

.project-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.project-details .portfolio-info h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.project-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.project-details .portfolio-info ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.project-details .portfolio-info ul strong {
  text-transform: uppercase;
  font-weight: 400;
  color: #838893;
  font-size: 14px;
}

.project-details .portfolio-info .btn-visit {
  padding: 8px 40px;
  background: var(--color-primary);
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
}

.project-details .portfolio-info .btn-visit:hover {
  background: #ffc019;
}

.project-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.project-details .portfolio-description p {
  padding: 0;
}

.project-details .portfolio-description .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: #f5f6f7;
  height: 100%;
  margin-bottom: 50px;
}

.project-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.project-details .portfolio-description .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 5px 0;
  padding-top: 20px;
}

.project-details .portfolio-description .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left,
.project-details .portfolio-description .testimonial-item .quote-icon-right {
  color: #ffd565;
  font-size: 26px;
  line-height: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.project-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.project-details .portfolio-description .testimonial-item p {
  font-style: italic;
  margin: 0 0 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Detail carousel
--------------------------------------------------------------*/
.detail-carousel {
  overflow-x: hidden;
  padding: 0;
}

.detail-carousel .carousel {
  width: 100%;
  min-height: 70vh;
  padding: 80px 0;
  margin: 0;
  position: relative;
}

@media (min-width: 768px) {
  .detail-carousel .carousel {
    min-height: 90vh;
  }
}

.detail-carousel .carousel-item {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1;
  transition-duration: 0.4s;
}

.detail-carousel .carousel-control-prev {
  justify-content: start;
}

@media (min-width: 640px) {
  .detail-carousel .carousel-control-prev {
    padding-left: 15px;
  }
}

.detail-carousel .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .detail-carousel .carousel-control-next {
    padding-right: 15px;
  }
}

.detail-carousel .carousel-control-next-icon,
.detail-carousel .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: rgba(33, 32, 32, 0.84);
  color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-carousel .carousel-control-prev,
.detail-carousel .carousel-control-next {
  z-index: 3;
  transition: 0.3s;
}

.detail-carousel .carousel-control-prev:focus,
.detail-carousel .carousel-control-next:focus {
  opacity: 0.5;
}

.detail-carousel .carousel-control-prev:hover,
.detail-carousel .carousel-control-next:hover {
  opacity: 0.9;
}