@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
// @import "~bootstrap/scss/bootstrap.scss";

// Vendor SCSS Files
@import "lib/bootstrap/bootstrap.min.scss";
@import "lib/bootstrap-icons/bootstrap-icons.scss";
@import "lib/font-awesome/all.min.scss";
@import "lib/aos/aos.scss";
@import "lib/glightbox/glightbox.min.scss";
@import "lib/swiper/swiper-bundle.min.scss";

@import "reboot";

@import "components/section";
@import "components/header";
@import "components/navbar";
@import "components/breadcrumbs";
@import "components/footer";

@import "pages/home";
@import "pages/projects";
@import "pages/project_details";
@import "pages/contact";